<template>
  <div>
    <div class="headtop">
      <img :src="getStorage('u_s',{}).headimg"
           alt="头像"
           class="headpic" />
      <!-- <p class="username">XGG</p> -->

      <div class="setupbar">
        <p class="username">{{getStorage('u_s',{}).empname}}</p>
        <div class="botbox">
          <div class="sub_setbar"
               @click="togo('1')">
            <img src="@/assets/xxb/shoucang.png"
                 alt />
            <span class="bet">我的提问</span>
            <span>{{myquestion}}</span>
          </div>
          <span class="border"></span>
          <div class="sub_setbar"
               @click="togo('2')">
            <img src="@/assets/xxb/msg.png"
                 alt />
            <span class="bet">我的消息</span>
            <span>{{mymsg}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="lessoncenter">
      <div class="pbox">
        <p class="md-tab-bar"
           v-for="(item,index) in items"
           :key="index"
           @click="toshow(item,index)">
          <span :class="item.ischecked?'oranger':''">{{item.label}}</span>
          <span class="_line"
                :class="item.ischecked?'c_line':''"></span>
        </p>
      </div>

      <div class="lesscontxt"
           v-if="!current">
        <p class="lesslist"
           v-for="i in wzlist"
           :key="i.wzcode"
           @click="towzdetails(i)">
          <span class="wztitle">{{i.wztitle}}</span>
          <md-icon name="arrow-right"
                   size="md"></md-icon>
        </p>
        <div class="empty"
             v-if="wzlist.length == 0">
          <img src="@/assets/xxb/empty.png"
               alt />
          <p @click="tostudy">立即学习</p>
        </div>
      </div>
      <div class="lesscontxt"
           v-else>
        <p class="lesslist"
           v-for="i in wzlist"
           :key="i.wzcode"
           @click="towzdetails(i)">
          <span class="wztitle">{{i.wztitle}}</span>
          <md-icon name="arrow-right"
                   size="md"></md-icon>
        </p>
        <div class="empty"
             v-if="wzlist.length == 0">
          <img src="@/assets/xxb/sc.png"
               alt />

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getStorage } from "@/lib/util";
import { wzhistory, getwzcollect, myPage } from "@/api/xxb";
export default {
  data () {
    return {
      current: 0,
      items: [
        { name: 0, label: "最近学习", ischecked: true },
        { name: 1, label: "我的收藏", ischecked: false },
      ],
      wzlist: [],
      mymsg: '',
      myquestion: ''
    };
  },
  created () {
    this.detail()
    myPage({ empno: getStorage("u_s", {}).empno }).then((res) => {
      this.mymsg = res.data.data.mymsg
      this.myquestion = res.data.data.myquestion
    })
  },
  methods: {
    detail () {
      if (!this.current) {
        wzhistory({ empno: getStorage("u_s", {}).empno }).then(res => {
          this.wzlist = res.data.data || [];
        });
      } else {
        getwzcollect({ empno: getStorage("u_s", {}).empno }).then((res) => {
          this.wzlist = res.data.data || [];
        })
      }

    },
    towzdetails (row) {
      this.$router.push({
        path: "/wzdetails",
        query: {
          wzcode: row.wzcode,
        }
      });
    },
    tostudy () {
      this.$router.push({
        path: "/study",
        query: {
          current: 1
        }
      });
    },
    togo (type) {
      this.$router.push({
        path: `/mytw?type=${type}`
      });
    },
    toshow (row, index) {
      this.items.forEach((item, index) => {
        item.ischecked = false
      })
      this.items[index].ischecked = true
      this.current = this.items[index].name
      this.detail()
    },
  }
};
</script>
<style lang="stylus" scoped>
.headtop {
  height: 5.5rem;
  background: url('~@/assets/xxb/perbg.jpg') no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .headpic {
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    border: 0.02rem sloid #fff;
    position: absolute;
    z-index: 10;
    top: 1.5rem;
  }
}

.setupbar {
  position: absolute;
  height: 3rem;
  width: 100%;
  bottom: 0;
  // display: flex;
  // align-items: center;
  background: white;
  border-radius: 20px 20px 0 0;

  // justify-content: center;
  p {
    font-size: 0.43rem;
    font-family: PingFang SC;
    font-weight: 500;
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
    text-align: center;
    color: #383838;
  }

  .botbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sub_setbar {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 0.45rem;
      height: 0.45rem;
      margin-right: 0.26rem;
    }

    span {
      color: #7D7D7D;
      font-size: 0.39rem;
      font-family: PingFang SC;
      font-weight: 500;
    }

    .bet {
      margin-right: 0.15rem;
    }
  }

  .border {
    display: block;
    height: 0.65rem;
    width: 1px;
    background: #EFEFEF;
  }
}

.lessoncenter {
  margin-top: 0.3rem;

  .md-tab-bar {
    background: white;
    padding-top: 0.3rem;
    font-size: 0.39rem;
    // color: #FB6821;
    padding-left: 0.71rem;
    display: inline-block;
    padding-bottom: 0.3rem;

    .oranger {
      color: #FB6821;
    }

    ._line {
      display: block;
      width: 0.31rem;
      height: 0.06rem;
      // background: #FB6821;
      margin-left: 0.6rem;
      margin-top: 0.05rem;
    }

    .c_line {
      background: #FB6821;
    }
  }
}

.lesscontxt {
  height: calc(100vh - 4.88rem - 0.3rem - 1.12rem);
  background: white;
  overflow-y: scroll;

  .empty {
    border-top: 1px solid #EFEFEF;
    margin: 0 0.36rem;

    p {
      width: 5.55rem;
      height: 0.98rem;
      border: 1px solid #FB6821;
      color: #FB6821;
      font-size: 0.36rem;
      font-family: PingFang SC;
      font-weight: 500;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      margin-top: 0.61rem;
    }
  }
}

.lesslist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.39rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
  margin: 0 0.7rem 0.5rem;

  .md-icon {
    color: #9B9B9B;
  }

  .wztitle {
    font-size: 0.39rem;
    color: #383838;
    font-weight: 500;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    width: 7.5rem;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.lesslist:nth-child(1) {
  padding-top: 0.38rem;
}

.pbox {
  background-color: #ffffff;
}
</style>